
import Vue from "vue";

export default Vue.extend({
  name: "sims-info",
  data() {
    return {
      sortIcon: "arrow-up",
      sortIconSize: "is-small"
    };
  },
  created() {
    this.getSimsInfo();
  },
  methods: {
    getSimsInfo() {
      this.$store.dispatch("getSimsInfo");
    }
  },
  computed: {
    simsInfoData(): undefined | any {
      return this.$store.state.simsInfo;
    }
  }
});
