
import NoTenantSelectedMessage from "@/components/NoTenantSelectedMessage.vue";
import Vue from "vue";
import { DateTime, DurationUnit } from "luxon";
import VehiclesBehaviorRightSidebar from "@/components/VehiclesBehaviorRightSidebar.vue";
import CustomVehicleIcon from "@/components/CustomVehicleIcon.vue";
import { filter } from "lodash";

export default Vue.extend({
  name: "MisbehavingVehicles",
  components: {
    VehiclesBehaviorRightSidebar,
    NoTenantSelectedMessage,
    CustomVehicleIcon
  },
  data() {
    return {
      tableProps: {
        loading: false,
        defaultSortDirection: "asc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small"
      },
      filters: {
        categoryQuery: undefined
      },
      selected: undefined
    };
  },
  computed: {
    vehiclesBehavior(): undefined | any {
      let filteredVehiclesBehavior = this.$store.state.vehiclesBehavior;
      // filter vehicle behaviors based on the selected filters
      if (this.filters.categoryQuery) {
        // change vehicle to show vehicles according to selected category
        filteredVehiclesBehavior = filteredVehiclesBehavior.filter(
          (vehicleBehavior: any) => {
            return vehicleBehavior.fleetCategory.name
              ?.toLowerCase()
              .includes(this.filters.categoryQuery!.toLowerCase());
          }
        );
      }
      // return filtered vehicles behavior
      return filteredVehiclesBehavior;
    },
    customCategories() {
      if (this.$store.state.vehiclesBehavior) {
        // using JSON stringify and parse to make object
        // comparison possible using Set to find unique array
        // filter to contain cateogries that exist within vehcile locations
        return filter(
          [
            ...new Set(
              this.$store.state.vehiclesBehavior.map((item: any) =>
                JSON.stringify(item.fleetCategory)
              )
            )
          ].map((item: any) => {
            return JSON.parse(item);
          }),
          (category) => {
            return category.name;
          }
        );
      } else return undefined;
    },
    selectedTenant() {
      return this.$store.state.selectedTenant;
    }
  },
  created() {
    this.initializeView();
  },
  watch: {
    selectedTenant: function () {
      this.initializeView();
    }
  },
  methods: {
    initializeView() {
      if (this.selectedTenant) {
        this.getVehiclesBehaviorReport();
      }
    },
    removeSelection() {
      this.selected = undefined;
    },
    handleRowClick(row) {
      if (row?.dongleId === this.selected?.dongleId) {
        // same row clicked agian - do nothing
      } else {
        // diff row clicked - reset store for dongel behavior events
        this.$store.commit("clearDongleBehaviorEvents");
      }
    },
    async getVehiclesBehaviorReport() {
      this.tableProps.loading = true;

      await this.$store.dispatch("getVehiclesBehaviorStatus", {
        selectedTenant: this.selectedTenant
      });

      if (this.vehiclesBehavior) {
        this.tableProps.loading = false;
      }
    },
    timeAgo(date) {
      // source https://github.com/moment/luxon/issues/274#issuecomment-729126515
      const units = [
        "year",
        "month",
        "week",
        "day",
        "hour",
        "minute",
        "second"
      ] as DurationUnit[];

      const dateTime = DateTime.fromISO(date);
      const diff = dateTime.diffNow().shiftTo(...units);
      const unit = units.find((unit) => diff.get(unit) !== 0) || "second";

      // todo: using any until we upgrade to TypeSript versin 4.2
      const relativeFormatter = new (Intl as any).RelativeTimeFormat("en", {
        numeric: "auto"
      });
      return relativeFormatter.format(Math.trunc(diff.as(unit)), unit as any);
    }
  }
});
