var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebar-right is-hidden-mobile"},[(_vm.detail)?_c('div',[_c('div',[_c('div',{staticClass:"p-5"},[_c('div',{staticClass:"columns mb-0"},[_c('div',{staticClass:"column pb-0"},[_c('p',{staticClass:"is-size-6"},[_vm._v("Dongle # "+_vm._s(_vm.detail.dongleId))]),_c('p',{staticClass:"is-size-6"},[_vm._v("Vehicle # "+_vm._s(_vm.detail.registrationNo))]),_c('p',{staticClass:"is-size-6 has-text-info"},[_vm._v(" Latest 100 EVENT & BATT values ")])]),_c('div',{staticClass:"column is-narrow pb-0"},[_c('button',{staticClass:"button button-round",on:{"click":() => {
                  this.$emit('sidebar-closed');
                }}},[_c('i',{staticClass:"mdi mdi-close"})])])])]),_c('b-tabs',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('b-tab-item',{staticClass:"px-2",attrs:{"label":"Events","value":"events"}},[(_vm.dongleBehaviorEvents)?_c('div',_vm._l((_vm.dongleBehaviorEvents),function(item){return _c('div',{key:item.id,staticClass:"mb-2"},[(item.mdi_ext_batt_voltage)?_c('div',[_c('p',{staticClass:"text-small mt-4"},[_vm._v(" "+_vm._s(_vm._f("date")(item.recordedAt,"numeric-short"))+"   •   "+_vm._s(_vm._f("date")(item.recordedAt,"time"))+" "),_c('br')]),_c('p',[_vm._v(" "+_vm._s(_vm._f("date")(item.recordedAt,"relative"))+" "),_c('br'),(item.mdi_ext_batt_voltage)?_c('span',{staticClass:"mr-1"},[_c('b-tooltip',{attrs:{"type":"is-light","position":"is-right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',[_vm._v("Dongle battery voltage")])]},proxy:true}],null,true)},[_c('b-tag',{attrs:{"type":item.mdi_ext_batt_voltage >= _vm.voltageThreshold
                            ? 'is-success is-light'
                            : 'is-danger is-light'}},[_c('i',{staticClass:"mdi mdi-battery-charging-80"}),_vm._v(" "+_vm._s((item.mdi_ext_batt_voltage / 1000).toFixed(3))+" V ")])],1)],1):_vm._e(),(item.event)?_c('span',{staticClass:"mr-1"},[_c('b-tag',{attrs:{"type":_vm.getEventType(item.event)}},[_vm._v("event • "+_vm._s(item.event))])],1):_vm._e(),(item.BATT === 1 || item.BATT === 0)?_c('span',{staticClass:"mr-1"},[_c('b-tag',{attrs:{"type":"is-warning"}},[_vm._v("batt • "+_vm._s(item.BATT)+" ")])],1):_vm._e(),_c('b-tag',{attrs:{"rounded":""}},[_vm._v("id: "+_vm._s(item.id))])],1),_c('hr')]):_vm._e()])}),0):_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"is-size-4 has-text-info"},[_vm._v("Loading...")])])]),_c('b-tab-item',{staticClass:"px-2",attrs:{"label":"Voltage History","value":"voltageHistory"}},[(_vm.dongleVoltageEvents)?_c('div',[_c('b-notification',{attrs:{"type":"is-warning","aria-close-label":"Close notification","role":"alert"}},[_vm._v(" Showing raw voltage history of dongle regarless of associated vehicle. ")]),_vm._l((_vm.dongleVoltageEvents),function(item){return _c('div',{key:item.id},[_c('div',{staticClass:"columns mt-2 is-vcentered"},[_c('div',{staticClass:"column"},[_vm._v(" "+_vm._s(_vm._f("date")(item.recordedAt,"date-time-seconds"))+" ")]),_c('div',{staticClass:"column is-narrow has-text-right"},[_c('span',{staticClass:"mr-1"},[_c('b-tooltip',{attrs:{"type":"is-light","position":"is-left"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',[_vm._v("Dongle battery voltage")])]},proxy:true}],null,true)},[_c('b-tag',{attrs:{"type":item.MDI_EXT_BATT_VOLTAGE >= _vm.voltageThreshold
                            ? 'is-success is-light'
                            : 'is-danger is-light'}},[_c('i',{staticClass:"mdi mdi-battery-charging-80"}),_vm._v(" "+_vm._s((item.MDI_EXT_BATT_VOLTAGE / 1000).toFixed(3))+" V ")])],1)],1),(item.MDI_EXT_BATT_LOW !== null)?_c('p',{staticClass:"mr-1 mt-2"},[_c('b-tooltip',{attrs:{"type":"is-light","position":"is-left"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('p',[_vm._v("Battery Low")])]},proxy:true}],null,true)},[_c('b-tag',{attrs:{"type":item.MDI_EXT_BATT_LOW
                            ? 'is-danger is-light'
                            : 'is-info is-light'}},[(item.MDI_EXT_BATT_LOW)?_c('span',[_c('i',{staticClass:"mdi mdi-flash"}),_vm._v(" True ")]):_c('span',[_c('i',{staticClass:"mdi mdi-flash"}),_vm._v(" False ")])])],1)],1):_vm._e()])]),_c('hr')])}),_c('div',{staticClass:"py-5"},[_c('b-button',{attrs:{"type":"is-primary","expanded":""},on:{"click":_vm.loadMoreVoltageEvents}},[_vm._v("Load further")])],1)],2):_c('div',{staticClass:"mt-4"},[_c('p',{staticClass:"is-size-4 has-text-info"},[_vm._v("Loading...")])])])],1)],1)]):_c('div',[_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"hero is-fullheight"},[_c('div',{staticClass:"hero-body has-text-centered sidebar-right-empty"},[_c('div',{staticClass:"container"},[_c('img',{attrs:{"src":require("@/assets/img/sidebar-right-idle-icon.svg")}}),_c('p',[_vm._v("Select a row in table for further details")])])])])
}]

export { render, staticRenderFns }