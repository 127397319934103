
import Vue from "vue";
import NoTenantSelectedMessage from "@/components/NoTenantSelectedMessage.vue";
import AlertComment from "@/components/AlertComment.vue";
import find from "lodash/find";
import { Trip, TripPage, Vehicle, AlertTemplate } from "@/shared/models";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import { DateTime } from "luxon";

export default Vue.extend({
  name: "Alerts",
  components: { NoTenantSelectedMessage, VueJsonPretty, AlertComment },
  data() {
    return {
      tableProps: {
        data: [],
        loading: false,
        total: 0,
        page: 1,
        perPage: 20
      },
      AlertTemplate,
      filters: {
        alertTemplate: undefined,
        startDate: undefined,
        endDate: undefined
      }
    };
  },
  computed: {
    alerts(): undefined | any {
      return this.$store.state.alerts;
    },
    selectedTenant() {
      return this.$store.state.selectedTenant;
    }
  },
  created() {
    this.initializeView();
  },
  watch: {
    selectedTenant: function() {
      this.initializeView();
    }
  },
  methods: {
    initializeView() {
      if (this.selectedTenant) {
        this.getAlerts();
      }
    },
    formatAlertTemplateName(alertTemplate: string) {
      // Remove Template sub string and add space before capital letters
      return alertTemplate
        .replace(/Template/g, "")
        .replace(/([A-Z])/g, " $1")
        .trim();
    },
    //Handle page-change event
    onPageChange(page: number) {
      this.tableProps.page = page;
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: page.toString()
        }
      });
      this.getAlerts();
    },
    async getAlerts() {
      this.tableProps.loading = true;
      const request = {
        selectedTenant: this.selectedTenant,
        page: this.tableProps.page,
        limit: this.tableProps.perPage,
        alertTemplate: this.filters.alertTemplate,
        startDate: this.filters.startDate
          ? DateTime.fromISO(this.filters.startDate.toISOString()).toISO({
              includeOffset: false
            })
          : undefined,
        endDate: this.filters.endDate
          ? DateTime.fromISO(this.filters.endDate.toISOString()).toISO({
              includeOffset: false
            })
          : undefined
      };
      await this.$store.dispatch("getAlerts", request);
      if (this.alerts) {
        this.tableProps.total = this.alerts.totalItems;
        this.tableProps.data = this.alerts.items;
        this.tableProps.loading = false;
      }
    }
  }
});
