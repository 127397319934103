
import notification from "@/services/notificationService";
import Vue from "vue";
import api from "../services/dataService";
// import VueJsonPretty from "vue-json-pretty";
import { DateTime } from "luxon";
import "vue-json-pretty/lib/styles.css";

export default Vue.extend({
  name: "VehicleSearch",
  components: {
    // VueJsonPretty
  },
  data() {
    return {
      dongleId: undefined,
      loading: false,
      vehicleDetail: undefined
    };
  },
  computed: {},
  created() {
    if (this.$route.query.dongleId) {
      this.dongleId = parseInt(this.$route.query.dongleId.toString());
      this.searchVehicleByDongleId();
    }
  },
  methods: {
    searchVehicleByDongleId() {
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          dongleId: this.dongleId
        }
      });
      this.loading = true;
      api
        .get("/vehicles/" + this.dongleId)
        .then(res => {
          this.vehicleDetail = res.data;
          this.loading = false;
        })
        .catch(err => {
          this.vehicleDetail = undefined;
          this.loading = false;
          notification.error(err.data.message);
        });
    }
  }
});
