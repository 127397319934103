
import Vue from "vue";
import { DateTime } from "luxon";

export default Vue.extend({
  name: "VehiclesBehaviorRightSidebar",
  props: {
    detail: Object
  },
  data() {
    return {
      DateTime: DateTime,
      selectedTab: "events" as "events" | "voltageHistory",
      voltageThreshold: 12000,
      voltageEventsColParams: {
        data: [],
        limit: 100,
        page: 1
      }
    };
  },
  watch: {
    detail: function() {
      this.getDongleBehaviorEvents();
      this.getDongleVoltageEvents();
    },
    selectedTab: function() {
      if (this.selectedTab === "events") {
        this.getDongleBehaviorEvents();
      }
      if (this.selectedTab === "voltageHistory") {
        this.getDongleVoltageEvents();
      }
    }
  },
  created() {
    this.getDongleBehaviorEvents();
    this.$store.dispatch("setDongleVoltageEvents", []);
  },
  computed: {
    dongleBehaviorEvents(): any {
      return this.$store.state.dongleBehaviorEvents;
    },
    dongleVoltageEvents() {
      return this.$store.state.dongleVoltageEvents;
    }
  },
  methods: {
    getDongleBehaviorEvents() {
      this.$store.dispatch("setDongleBehaviorEvents", undefined);
      if (this.detail?.dongleId) {
        const request = {
          dongleId: this.detail.dongleId
        };
        this.$store.dispatch("getDongleBehaviorEvents", request);
      }
    },
    async getDongleVoltageEvents() {
      this.$store.dispatch("setDongleVoltageEvents", []);
      this.voltageEventsColParams.data = [];
      this.voltageEventsColParams.limit = 100;
      this.voltageEventsColParams.page = 1;
      if (this.detail?.dongleId) {
        const request = {
          dongleId: this.detail.dongleId,
          limit: this.voltageEventsColParams?.limit,
          page: this.voltageEventsColParams?.page
        };
        const res = await this.$store.dispatch(
          "getDongleVoltageEvents",
          request
        );
        if (res) {
          this.voltageEventsColParams.limit = res.itemsPerPage;
          this.voltageEventsColParams.page = res.currentPage;
        }
      }
    },
    async loadMoreVoltageEvents() {
      if (this.detail?.dongleId) {
        const request = {
          dongleId: this.detail.dongleId,
          limit: this.voltageEventsColParams?.limit,
          page: this.voltageEventsColParams?.page + 1
        };
        const res = await this.$store.dispatch(
          "getDongleVoltageEvents",
          request,
          true // this append flag basically concatenates the array otherwise array is overridden in store.
        );
        if (res) {
          this.voltageEventsColParams.limit = res.itemsPerPage;
          this.voltageEventsColParams.page = res.currentPage;
        }
      }
    },
    getEventType(e) {
      // console.log(e);
      if (e.toLowerCase().includes("s:")) {
        // sleep events
        return "is-danger";
      } else if (e.toLowerCase().includes("b:")) {
        // wakeup evnets
        return "is-success";
      }
      // default
      return "is-info is-light";
    }
  }
});
