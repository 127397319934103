
import Vue from "vue";
import { AlertTemplate } from "@/shared/models";
export default Vue.extend({
  name: "AlertComment",
  props: {
    alert: Object
  },
  data() {
    return { AlertTemplate };
  }
});
