
import NoTenantSelectedMessage from "@/components/NoTenantSelectedMessage.vue";
import { AlertTemplate } from "@/shared/models";
import { DateTime } from "luxon";
import Vue from "vue";

export default Vue.extend({
  name: "AlertsSummary",
  components: { NoTenantSelectedMessage },
  data() {
    return {
      tableProps: {
        data: [],
        loading: false
      },
      AlertTemplate,
      filters: {
        alertTemplate: undefined,
        date: undefined
      }
    };
  },
  computed: {
    alertHistorySummary(): undefined | any {
      return this.$store.state.alertHistorySummary;
    },
    selectedTenant() {
      return this.$store.state.selectedTenant;
    }
  },
  created() {
    this.initializeView();
  },
  watch: {
    selectedTenant: function() {
      this.initializeView();
    }
  },
  methods: {
    initializeView() {
      if (this.selectedTenant) {
        this.filters.date = new Date();
        this.getAlertHistorySummary();
      }
    },
    formatAlertTemplateName(alertTemplate: string) {
      // Remove Template sub string and add space before capital letters
      return alertTemplate
        .replace(/Template/g, "")
        .replace(/([A-Z])/g, " $1")
        .trim();
    },

    async getAlertHistorySummary() {
      this.tableProps.loading = true;
      const request = {
        selectedTenant: this.selectedTenant,
        alertTemplate: this.filters.alertTemplate,
        date: this.filters.date
          ? DateTime.fromISO(this.filters.date.toISOString()).toISO({
              includeOffset: false
            })
          : undefined
      };
      await this.$store.dispatch("getAlertHistorySummary", request);
      if (this.alertHistorySummary) {
        this.tableProps.loading = false;
        this.tableProps.data = this.alertHistorySummary;
      }
    }
  }
});
